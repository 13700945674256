
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";
import OrgPickerComponent from "../../../components/OrgPicker/OrgPicker.vue";

@Options({
    name: "flowable-common-form-components-org-picker",
    components: { OrgPicker: OrgPickerComponent },
    props: {
        placeholder: {
            type: String,
            default: "请选择组织",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        readerMode: {
            type: Boolean,
            default: false,
        },
    },
})
export default class OrgPicker extends ComponentMixins {
    public selected(values: any) {
        this._value = values;
    }
    public delOrg(i: any) {
        this._value.splice(i, 1);
    }
}
